.swiper-wrapper {
  box-sizing: border-box;
}
.swiper-container-horizontal {
  overflow: hidden;
}
.swiper-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  background-color: rgba($black,.2);
  background-image: none;
  border-radius: 50%;
  outline: none;
  transition: all 150ms ease;
  z-index: 2;
  .icon {
    width: 16px;
    height: 16px;
    fill: $white;
  }
  &:hover {
    background-color: rgba($default,.6);
  }
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 5px;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 5px;
}

// disabled
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  visibility: hidden;
}

// scroll bar

.swiper-container-horizontal > .swiper-scrollbar {
  left: 0;
  width: 100%;
  opacity: 1 !important;
  bottom: 0;
  height: 4px;
  background-color: $gray-100;
  z-index: 1;
}
.swiper-scrollbar-drag {
  background-color: $secondary;
}

// swiper pagination
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 30px;
  top: unset;
  z-index: 1;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px;
}
.swiper-pagination-bullet {
  border-radius: 0;
  width: 50px;
  height: 5px;
  opacity: 1;
  background-color: $white;
}
.swiper-pagination-bullet-active {
  background-color: $default;
}

