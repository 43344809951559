.modal {
  width: 100%;
  min-width: 800px;
  &__min {
    width: 100%;
    min-width: 400px;
    max-width: 600px;
  }
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px;
    text-align: center;
    overflow: auto;
    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      font-size: 0;
      height: 100%;
      width: 0;
    }
  }
  &__bg {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black,.85);
  }
  &__wrapper {
    display: inline-block;
    vertical-align: middle;
    background-color: $light;
    position: relative;
    padding: 20px;
  }
  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: initial;
    position: absolute;
    top: 0;
    right: 0;
    opacity: .4;
    .icon {
      width: 18px;
      height: 18px;
    }
    &:hover {
      opacity: .6;
    }
  }
  &__title {
    font-size: 24px;
    line-height: 28px;
    padding-top: 10px;
      padding-left: 30px;
      padding-right: 30px;
    margin-bottom: 30px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
