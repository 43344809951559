.filter {
  &__top {
    display: none;
  }
  &__item {
    background-color: $white;
    margin-bottom: 20px;
    padding: 20px;
    &-block {
      margin-bottom: 25px;
      &:only-child {
        margin-bottom: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 15px;
      .icon {
        width: 12px;
        height: 12px;
      }
      span {
        display: block;
        font-size: 16px;
        line-height: 21px;
        color: $primary;
        font-weight: 500;
      }
      &:hover {
        color: $primary;
      }
      &.is-hide {
        .icon {
          transform: rotate(-90deg);
        }
      }
    }
  }
  &__sort {
    &-item {
      margin-bottom: 10px;
    }
  }
  &__range {
    display: flex;
    &-item {
      width: 50%;
    }
    &-label {
      display: flex;
      align-items: center;
      span {
        display: block;
        width: 30px;
        min-width: 30px;
        padding: 0 5px;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
      }
    }
    &-input {
      display: block;
      width: 100%;
      padding: 8px 12px;
      background-color: $light;
      font-size: 14px;
      color: $default;
    }
      &-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 0 10px;
          height: 34px;
          font-size: 13px;
          font-weight: 500;
          background-color: $gray-100;
          text-transform: uppercase;
          color: $default;
          margin-left: 10px;
          &:hover {
              color: $white;
              background-color: $secondary;
          }
      }
  }
  &__close {
    display: none;
  }
    &__reset {
        margin-bottom: 20px;
        .btn {
            width: 100%;
            padding: 0 15px;
        }
    }
    &__bubble {
        display: none;
        position: absolute;
        left: 100%;
        min-width: 240px;
        border-radius: 3px;
        background-color: rgba($default,.8);
        text-align: center;
        box-shadow: 0 5px 20px rgba($black,.15);
        z-index: 50;
        &.is-show {
            display: block;
        }
        &-content {
            display: flex;
            align-items: center;
            padding: 12px 15px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: -5px;
                margin-top: -4px;
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-right:5px solid rgba($default,.8);
            }
        }
        &-link {
            display: block;
            font-size: 13px;
            line-height: 15px;
            color: yellow;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        &-text {
            display: block;
            font-size: 13px;
            line-height: 1;
            margin-left: 5px;
            color: $white;
            white-space: nowrap;
        }
    }
    &__tags {
        &-block {
            margin-bottom: 10px;
        }
        &-title {
            display: block;
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 5px;
            color: $default;
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
        }
        &-item {
            margin-right: 5px;
            margin-bottom: 5px;
        }
        &-link {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 13px;
            line-height: 17px;
            color: $white;
            height: 25px;
            padding: 0 10px;
            background-color: $default-100;
            .icon {
                width: 10px;
                height: 10px;
                margin-left: 5px;
            }
            &:hover {
                background-color: $black;
            }
        }
    }
}
