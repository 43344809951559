.footer {
  margin-top: auto;
  background-color: $default;
  color: $white;
  &__top {
    padding: 40px 0;
  }
  &__bottom {
    text-align: center;
    padding: 15px 0;
    background-color: $default-100;
      &-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
  }
  &__copy {
    font-size: 12px;
    line-height: 19px;
    color: $gray-100;
    font-weight: 300;
  }
  &__logo {
    display: block;
    width: 140px;
  }
  &__item {
    &-top {
      padding: 10px 0;
      margin-bottom: 20px;
      border-bottom: 1px solid $default-100;
    }
    &-title {
      display: block;
      font-size: 18px;
      line-height: 23px;
      font-weight: 300;
      color: $white;
      padding-top: 12px;
    }
  }
  &__block {
    &-element {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      color: $white;
      .icon {
        width: 25px;
        min-width: 25px;
        height: 25px;
      }
    }
    &-description {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
    }
    &-text {
      display: block;
      font-size: 14px;
      line-height: 21px;
      color: $white;
      padding: 2px 0;
    }
    &-link {
      display: block;
      font-size: 14px;
      line-height: 21px;
      color: $white;
      padding: 2px 0;
      &:hover {
        color: $primary;
      }
    }
  }
  &__nav {
    &-item {
      margin-bottom: 5px;
    }
    &-link {
      font-size: 14px;
      line-height: 21px;
      color: $white;
      &:hover {
        color: $primary;
      }
    }
  }
}
