// 1200
@media screen and (min-width: 1200px) {
  .header {
    &__nav {
      &-item {
        margin-right: 40px;
      }
        &-link {
            font-size: 14px;
        }
    }
    &__search {
      .input {
        width: 320px;
      }
    }
  }
  .menu {
    &__item {
        &-img {
            margin-top: 12px;
        }
        &-title {
            font-size: 13px;
            padding-top: 10px;
        }
    }
  }
  .hero {
    &__item {
      &:after {
        padding-bottom: 50%;
      }
    }
  }
  .promo {
    &__item {
      &-img {
        //height: 600px;
      }
      &-title {
        font-size: 50px;
        line-height: 56px;
      }
    }
  }
  .blog {
    &__item {
      &-title {
        font-size: 24px;
        line-height: 30px;
      }
      &-description {
        font-size: 16px;
        line-height: 24px;
      }
      &-btn {
        font-size: 16px;
      }
    }
  }
  .catalog {
    &__sort {
      &-item {
        margin-right: 30px;
      }
    }
    &__select {
      .select {
        min-width: 240px;
      }
    }
  }
  .product {
    &__slider {
      &-picture {
        padding: 40% 0;
      }
    }
    &__title {
      margin-bottom: 25px;
    }
    &__subtitle {
      margin-bottom: 30px;
    }
    &__options {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
    &__price {
      font-size: 34px;
      &-old {
        font-size: 16px;
      }
    }
    &__variants {
      &-title {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}

// 1360
@media screen and (min-width: 1360px) {
  .header {
    &__nav {
      &-item {
        margin-right: 50px;
      }
        &-link {
            font-size: 15px;
        }
    }
  }
    .menu {
        &__item {
            &-title {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
  .hero {
    &__item {
      &:after {
        padding-bottom: 40%;
      }
    }
  }
  .product {
    &__item {
      &-price {
        font-size: 20px;
      }
    }
    &__title {
      font-size: 34px;
      line-height: 42px;
    }
    &__price {
      font-size: 40px;
      &-old {
        font-size: 18px;
      }
    }
    &__variants {
      &-title {
        font-size: 16px;
      }
    }
  }
}

// 1440
@media screen and (min-width: 1440px) {
  .hero {
    &__item {
      &-block {
        padding: 30px;
        margin-bottom: 60px;
      }
      &-title {
        font-size: 66px;
        line-height: 66px;
        margin-top: -65px;
      }
      &-subtitle {
        font-size: 44px;
        line-height: 44px;
        padding-top: 20px;
        margin-bottom: 25px;
      }
      &-description {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}

