.pagination {
  padding: 10px 0;
  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $default;
    width: 40px;
    height: 40px;
    margin: 0 3px;
    border-radius: 50%;
    cursor: default;
    transition: all 150ms ease;
    background-color: $white;
    &:first-child {
      background-color: $white;
      margin-right: 30px;
    }
    &:last-child {
      background-color: $white;
      margin-left: 30px;
    }
    &.is-active {
      color: $white;
      background-color: $secondary;
      .pagination__link {
        color: $white;
      }
    }
    &:hover:not(.is-active):not(.disabled) {
      color: $primary;
    }
    &.disabled {
      opacity: .6;
      pointer-events: none;
    }
  }
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $default;
    font-size: 14px;
    line-height: 16px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    .icon {
      width: 10px;
      height: 10px;
    }
    &:hover {
      color: $secondary;
    }
  }
}
