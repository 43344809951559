.product__item {
  background-color: $white;
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 20px;
  transition: all 150ms ease;
  &:hover {
    box-shadow: 2px 4px 10px rgba($black, 0.1);
    .product__item {
      &-buy {
        opacity: 1;
        height: auto;
        transform: translateY(0);
        padding: 10px;
      }
    }
  }
  &-top {
    position: relative;
  }
  &-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
  }
  &-link {
    display: block;
    padding: 10px;
  }
  &-picture {
    display: block;
    position: relative;
    height: 0;
    overflow: hidden;
    padding: 50% 0;
  }
  &-img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &-description {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 10px;
    background-color: $white;
  }
  &-title {
    display: block;
    color: $default;
    height: 40px;
    overflow: hidden;
    &:hover {
      color: $primary;
    }
  }
  &-name {
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    color: inherit;
  }
  &-sku {
    display: block;
    font-size: 12px;
    line-height: 17px;
    color: $gray-200;
    min-width: 100px;
    text-align: right;
    padding-left: 5px;
  }
  &-buy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $gray-100;
    opacity: 0;
    height: 0;
    padding: 5px 10px;
    transform: translateY(100%);
    transition: all ease 150ms;
  }
  &-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 300;
    text-align: center;
    height: 35px;
    padding: 0 10px;
    position: relative;
    color: $white;
    background-color: $default;
    width: 50%;
    &:hover {
      background-color: darken($default, 10%);
    }
    .icon {
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }
    &.is-disable {
      color: $gray;
      background-color: $gray-100;
      &:hover {
        color: $white;
        background-color: $gray;
      }
    }
  }
  &-cost {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    text-align: right;
    width: 50%;
  }
  &-price {
    display: block;
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    color: $secondary;
    &__old {
      display: block;
      font-size: 13px;
      line-height: 1;
      text-decoration: line-through;
      color: $default-200;
      margin-bottom: 2px;
    }
    &__unavailable {
      display: block;
      font-size: 13px;
      line-height: 1;
      color: $gray;
    }
  }

}