.header {
  background-color: $white;
  &__top {
    border-bottom: 1px solid $gray-100;
    &-wrapper {
      display: flex;
      align-items: center;
      height: 40px;
    }
  }
  &__bottom {
    &-wrapper {
      display: flex;
      align-items: center;
      height: 80px;
      position: relative;
    }
    &-left {
      width: 33.3%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-center {
      width: 33.3%;
      padding: 0 15px;
      margin: 0 15px;
    }
    &-right {
      width: 33.3%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__nav {
    &-list {
      display: flex;
    }
    &-item {
      margin-right: 25px;
    }
    &-link {
      font-size: 13px;
      line-height: 19px;
      color: $default;
      &:hover {
        color: $primary;
      }
    }
  }
  &__search {
    margin-left: auto;
    &-form {
      position: relative;
    }
    .input {
      padding-right: 40px;
      width: 220px;
    }
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      color: $gray;
      &:hover {
        color: $primary;
      }
      .icon {
        width: 14px;
        height: 14px;
      }
    }
      &-list {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          background-color: $white;
          z-index: 10;
      }
      &-item {}
      &-link {
          display: flex;
          padding: 10px;
          background-color: $white;
          &:hover {
              background-color: $light;
          }
      }
      &-img {
          display: block;
          width: 50px;
          min-width: 50px;
          height: 50px;
          object-fit: contain;
      }
      &-description {
          display: block;
          width: 100%;
          padding-left: 10px;
      }
      &-title {
          display: block;
          width: 100%;
          margin-bottom: 5px;
          font-size: 14px;
          line-height: 19px;
          color: $default;
          font-weight: bold;
          padding-top: 3px;
      }
      &-price {
          display: block;
          &__old {
              font-size: 12px;
              line-height: 17px;
              color: $gray;
              padding-left: 10px;
              text-decoration: line-through;
          }
          &__current {
              font-size: 14px;
              line-height: 17px;
              color: $secondary;
              font-weight: bold;
          }
      }
      &-empty {
          display: block;
          padding: 10px;
          text-align: center;
          font-size: 14px;
          line-height: 19px;
          color: $gray;
          font-style: italic;
      }
  }
  &__language {
    margin-left: 20px;
    &-list {
      display: flex;
    }
    &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        min-width: 30px;
        height: 30px;
      font-size: 12px;
      line-height: 19px;
      color: $gray;
        background-color: $light;
      text-transform: uppercase;
      position: relative;
    }
    &-link {
      display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        min-width: 30px;
        height: 30px;
      font-size: 12px;
      line-height: 19px;
      color: $default;
        background-color: $white;
      text-transform: uppercase;
      &:hover {
        color: $white;
          background-color: $default;
      }
    }
  }

  &__menu {
    &-btn {
      display: flex;
      align-items: center;
      color: $secondary;
      .icon {
        width: 25px;
        min-width: 25px;
        height: 25px;
      }
      span {
        display: block;
        font-size: 16px;
        line-height: 23px;
        font-weight: bold;
        padding-left: 5px;
      }
    }
      &-mobile {
          display: none;
      }
  }

  &__logo {
    display: block;
    width: 220px;
    margin: 0 auto;
  }
  &__contacts {
    display: flex;
    align-items: center;
    .icon {
      width: 25px;
      height: 25px;
      min-width: 25px;
    }
    &-list {
      padding-left: 15px;
    }
    &-item {
      font-size: 13px;
      line-height: 19px;
    }
    &-link {
      display: block;
      font-size: 13px;
      line-height: 19px;
      color: $default;
      &:hover {
        color: $primary;
      }
    }
  }
  &__user {
    display: flex;
    &-item {
      display: flex;
      align-items: center;
      margin-left: 10px;
      color: $default;
      &:hover {
        color: $primary;
      }
    }
    &-icon {
      position: relative;
      width: 20px;
      height: 20px;
      min-width: 20px;
      .icon {
        width: 20px;
        height: 20px;
      }
    }
    &-indicator {
      display: block;
      position: absolute;
      top: -5px;
      right: -5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $secondary;
    }
    &-counter {
      display: block;
      font-size: 12px;
      line-height: 18px;
      padding-left: 5px;
    }
  }

  &__burger {
    display: none;
  }

}
