.menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-top: 1px solid $gray-100;
  background-color: $white;
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  transition: all 150ms ease;
  border-bottom: 1px solid $gray-100;
  border-left: 1px solid $gray-100;
  border-right: 1px solid $gray-100;
  z-index: 20;
  &__show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  &__list {
    display: flex;
    width: 100%;
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
      width: 100%;
    border-right: 1px solid $gray-100;
    transition: all 400ms ease;
    &:last-of-type {
      border-right-color: transparent;
    }
    &:hover {

      .menu {
          &__item {
              &-title {
                  color: $default;
              }
          }
        &__subitem {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
    &-link {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
      width: 100%;
      height: 90px;
        padding: 5px;
      overflow: hidden;
      position: relative;
    }
    &-img {
      display: block;
      width: 30px;
      min-width: 30px;
      height: 30px;
        margin-top: 10px;
      object-fit: contain;
    }
    &-title {
      display: block;
      width: 100%;
      font-size: 12px;
      line-height: 15px;
        color: $primary;
      font-weight: 500;
        padding-top: 8px;
    }
    &-subtitle {
      display: block;
      width: 100%;
      font-size: 13px;
      line-height: 17px;
      padding-top: 2px;
    }
    &-btn {
      display: none;
    }
  }

  &__subitem {
    //display: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: -1px;
      right: -1px;
    padding: 15px;
    border: 1px solid $gray-100;
    background-color: $white;
    transform: translateY(50px);
    transition: all 150ms ease;

    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
    &-item {
      padding: 0 10px;
      margin-bottom: 15px;
      width: 25%;
    }
    &-link {
      display: block;
      text-align: center;
      color: $gray;
      &:hover {
        color: $primary;
      }
    }
    &-picture {
      display: block;
      position: relative;
      height: 0;
      overflow: hidden;
      padding-bottom: 56.25%;
    }
    &-img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &-title {
      display: block;
      font-size: 14px;
      line-height: 19px;
      padding-top: 10px;
    }
  }
}
